import React from 'react';
import { observer } from 'mobx-react';
import { V1VerificationStatus as KycVerificationStatus } from '@vtblife/client-verification-api/axios-gen/kyc_verification_service';

import { KycVerificationHeader } from './kyc-verification-header';
import { KycVerificationModal } from './kyc-verification-modal';
import { UserStore } from '../../stores/user';
import { ConfigStore } from '../../stores/config';
import { isAutotestUserAgent } from '../../utils';

const REQUIRED_VERIFICATION_REMINDER_STATUSES: KycVerificationStatus[] = [
    'STATUS_UNSPECIFIED',
    'STATUS_ADDITIONAL_DATA_REQUIRED',
    'STATUS_ON_USERS_ADJUSTMENT',
];

export const KycVerificationReminder: React.FC<{
    userStore: UserStore;
    configStore: ConfigStore;
}> = observer(({ userStore, configStore }) => {
    if (
        !userStore.kycVerificationStatus ||
        !REQUIRED_VERIFICATION_REMINDER_STATUSES.includes(userStore.kycVerificationStatus) ||
        isAutotestUserAgent(window?.navigator?.userAgent || '')
    ) {
        return null;
    }
    const { isBlocker, mode } = userStore.kycVerificationReminder || {};
    if (mode === 'header') {
        return <KycVerificationHeader onClose={userStore.closeKycVerificationReminder} />;
    }
    if (mode === 'modal') {
        return (
            <KycVerificationModal
                cdnUrl={configStore.cdnUrl}
                onClose={userStore.closeKycVerificationReminder}
                status={userStore.kycVerificationStatus}
                isBlocker={Boolean(isBlocker)}
            />
        );
    }
    return null;
});
