import * as Sentry from '@sentry/browser';
import { isError } from '@sentry/utils';
import { renderToStaticMarkup } from 'react-dom/server';
import Cookies from 'js-cookie';
import { NotificationErrorData, NotificationPayload } from '@vtblife/event-bus-events';
import { CaptureContext, SeverityLevel, Primitive } from '@sentry/types';

import {
    ComprehensiveErrorData,
    extractErrorDataByType,
    mergeErrorDataWithNotificationPayload,
} from './error-details-extractors';
import { ErrorType, HEADER_NAMES } from '../../constants';
import { isBlockedByQrator } from './error-handler';

export interface ReportableErrorData extends NotificationErrorData {
    description?: string;
    level?: SeverityLevel;
}

const shouldSendToSentry = (type?: ErrorType) => {
    if (Cookies.get('accept_outdated_browser')) {
        return false;
    }
    switch (type) {
        case ErrorType.Offline:
        case ErrorType.Unauthorized:
            return false;
        case ErrorType.Other:
        default:
            return true;
    }
};

const defineEventLevel = (type?: ErrorType): SeverityLevel => {
    if (type === ErrorType.Forbidden) {
        return 'warning';
    }
    return 'error';
};

const getReasonTag = (extractedData?: ComprehensiveErrorData, error?: any) => {
    switch (true) {
        case extractedData?.data?.httpStatus === 403 && isBlockedByQrator(error):
            return 'BLOCKED_BY_QRATOR';
        case extractedData?.data?.cfRay && !extractedData.data.traceId && extractedData.data.httpStatus === 503:
            return 'BLOCKED_BY_CLOUDFLARE';
        case extractedData?.data?.cfRay && !extractedData.data.traceId && extractedData.data.httpStatus === 520:
            return 'NETWORK_ERROR';
        default:
            return undefined;
    }
};

const report = ({
    error,
    extra: receivedExtra,
    extractedData,
    description,
    level,
    tags: externalTags = {},
    fingerprint: externalFingerprint = [],
}: ReportableErrorData & { extractedData?: ComprehensiveErrorData }) => {
    if (!shouldSendToSentry(extractedData?.data?.parsedType)) {
        return;
    }
    const errorData = {
        ...extractedData,
        ui: {
            ...extractedData?.ui,
            message: renderToStaticMarkup(extractedData?.ui?.message as React.ReactElement),
            additionalMessage: renderToStaticMarkup(extractedData?.ui?.additionalMessage as React.ReactElement),
        },
    };
    const extra: Record<string, any> = {
        description,
        errorData,
        ...receivedExtra,
        error,
    };

    const fingerprint = ['{{ default }}'];

    const tags: Record<string, Primitive> = {
        [HEADER_NAMES.traceId]: extractedData?.data?.traceId,
        requestUri: extractedData?.data?.requestUri,
        statusCode: extractedData?.data?.httpStatus,
        reason: getReasonTag(extractedData, error),
        ...externalTags,
    };

    if (tags.reason) {
        extra.response = error.response;
    }

    if (tags.requestUri && tags.statusCode) {
        fingerprint.push(String(tags.requestUri), String(tags.statusCode));
    }

    const userScope = Sentry?.getCurrentHub()?.getScope()?.getUser() || {};

    const errorMessage = (typeof error === 'string' ? error : error?.message) || 'unknown error ' + Date.now();

    const context: CaptureContext = {
        user: {
            ...userScope,
            ip_address: extractedData?.additionalData?.deviceIp || '',
        },
        level: level || defineEventLevel(extractedData?.data?.parsedType),
        extra,
        tags,
        fingerprint: externalFingerprint?.length > 0 ? externalFingerprint : fingerprint,
    };

    if (!isError(error)) {
        return Sentry.captureMessage(errorMessage, context);
    }

    return Sentry.getCurrentHub()
        .getClient()
        ?.eventFromException(error, {
            captureContext: context,
        })
        .then(
            (event) => {
                event.message = errorMessage;
                return Sentry.getCurrentHub().captureEvent(event, { captureContext: context });
            },
            (error) => {
                if (context.extra) {
                    context.extra.eventParseError = error;
                }
                return Sentry.captureMessage(errorMessage, context);
            },
        );
};

export async function reportErrorToSentry({
    error,
    extra,
    level,
    additionalData = {},
    description,
    notificationPayload,
    tags,
    fingerprint,
}: ReportableErrorData & {
    notificationPayload?: NotificationPayload;
    additionalData?: Record<string, any>;
}) {
    let extractedData: ComprehensiveErrorData = {
        ...extractErrorDataByType(error),
        additionalData,
    };

    if (notificationPayload) {
        extractedData = mergeErrorDataWithNotificationPayload(extractedData, notificationPayload);
    }

    const sentryEventId = await report({
        error,
        extra,
        level,
        extractedData,
        description,
        tags,
        fingerprint,
    });

    return { sentryEventId, extractedData };
}

export const restartSentryReplay = async () => {
    const hub = Sentry.getCurrentHub();
    const replay = hub.getIntegration(Sentry.Replay);
    if (replay) {
        await replay.flush();
        await replay.stop();
        replay.start();
    }
};
