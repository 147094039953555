import * as Sentry from '@sentry/browser';
import { matchPath } from 'react-router-dom';
import { makeMultiplexedTransport } from '@sentry/core';
import { stackParserFromStackParserOptions, supportsFetch } from '@sentry/utils';
import { getDefaultOptions, getEnvFromLocation } from '@vtblife/sentry';
import { BrowserClientOptions } from '@sentry/browser/types/client';
import { Route, MicroApp } from '@vtblife/layout-config/types';

type SentryMatcher = Parameters<typeof makeMultiplexedTransport>[1];

const OriginalBrowserClient = Sentry.BrowserClient;

// TODO: remove this BrowserClient override after microfrontend migration to updated @vtblife/sentry module
class SentryBrowserClientAdapter extends Sentry.BrowserClient {
    // @ts-expect-error
    constructor(overrideOptions: BrowserClientOptions) {
        const defaultIntegrations =
            Sentry.getCurrentHub().getStack()[0]?.client?.getOptions()?.integrations || Sentry.defaultIntegrations;

        const options = getDefaultOptions({
            ...overrideOptions,
            transport: supportsFetch() ? Sentry.makeFetchTransport : Sentry.makeXHRTransport,
            stackParser: stackParserFromStackParserOptions(Sentry.defaultStackParser),
            integrations: defaultIntegrations,
        });

        return new OriginalBrowserClient(options as BrowserClientOptions);
    }
}

function getSentryOptions({
    environment,
    routes,
    applications,
}: {
    environment: string | undefined;
    routes: Route[];
    applications: MicroApp[];
}): Sentry.BrowserOptions {
    let externalConfigurationOptions = {};
    try {
        externalConfigurationOptions = JSON.parse(process.env.SENTRY_CONFIGURATION_OPTIONS || '{}');
    } catch {
        // do nothing, just incorrect external options
    }

    /**
     * The function retrieves the Sentry DSN based on the current path and the application name.
     */
    const dsnFromFeature = (_param: Parameters<SentryMatcher>[0]) => {
        const path = window.location.pathname;
        const requiredAppName = routes.find((route) => matchPath(path, { path: route.path }))?.applicationName;
        if (requiredAppName) {
            const sentryDsn =
                applications.find((app) => app.applicationName === requiredAppName)?.logger?.sentryDsn || [];
            return sentryDsn;
        }
        return [];
    };

    const defaultOptions = getDefaultOptions({
        dsn: process.env.SENTRY_DSN,
        release: process.env.CI_COMMIT_SHORT_SHA,
        environment: environment || getEnvFromLocation(),
        enabled: process.env.SENTRY_ENABLED === 'true',
        transport: makeMultiplexedTransport(
            supportsFetch() ? Sentry.makeFetchTransport : Sentry.makeXHRTransport,
            dsnFromFeature,
        ),
        ...externalConfigurationOptions,
    });

    const isProd = environment === 'prod';

    //TODO up sentry pack
    const integrations = defaultOptions.integrations || [];
    integrations.push(
        new Sentry.Replay({
            maskAllText: isProd,
            maskAllInputs: isProd,
            networkDetailAllowUrls: isProd ? [] : [window.location.origin],
        }),
    );

    return {
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        ...defaultOptions,
        integrations,
    };
}

export function configureSentry(environment: string | undefined, extra?: Record<string, any>) {
    const applications = extra?.configStore?.microAppsConfig?.applications || [];
    const routes = extra?.bundleTreeStore?.bundleTree?.routes || [];
    const options = getSentryOptions({ environment, routes, applications });

    Sentry.init(options);

    // @ts-expect-error remove this logic after microfrontend migration to updated @vtblife/sentry module
    Sentry.BrowserClient = SentryBrowserClientAdapter;
}
