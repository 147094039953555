import React, { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, useCollapseItem, Typography, Link } from '@vtblife/uikit';
import cn from 'classnames';

import { cookieApiService } from '../../../services/cookie-api';
import { reportErrorToSentry } from '../../utils';
import { isAutotestUserAgent } from '../../../utils';

const CookieConsentHeaderReactive = () => {
    const { opened } = useCollapseItem();
    return <Typography variant="secondary">{opened ? 'Скрыть' : 'Подробнее'}</Typography>;
};

export const CookieConsent = () => {
    const [isAutotest, setIsAutotest] = useState(false);
    const acceptCookies = useCallback(() => {
        cookieApiService
            .setCookieConsent()
            .catch((error) => {
                reportErrorToSentry({
                    error,
                    extra: { when: 'Failed to set cookie consent' },
                    level: 'warning',
                });
            })
            .finally(() => {
                window.__hideCookiesModal();
            });
    }, []);

    const rejectCookies = useCallback(() => {
        window.localStorage.setItem('cookies_rejected', 'true');
        window.__hideCookiesModal();
    }, []);

    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            setIsAutotest(isAutotestUserAgent(window.navigator.userAgent));
        }
    }, []);

    return (
        // never change this className, see __hideCookiesModal logic
        <div className={cn('cookie-consent-wrapper', isAutotest && 'cookie-consent-wrapper-autotest')}>
            <div className="cookie-consent-content">
                <Collapse transparent>
                    <Collapse.Item>
                        <Collapse.Title>
                            <div className="cookie-concent-title">
                                <Typography variant="secondary" color="secondary">
                                    Мы&nbsp;используем файлы cookie и&nbsp;сервисы веб-аналитики.
                                </Typography>
                                <CookieConsentHeaderReactive />
                            </div>
                            <Collapse.TitleAddon></Collapse.TitleAddon>
                        </Collapse.Title>
                        <Typography variant="secondary" className="cookie-consent-text">
                            Нажимая &laquo;Принять все&raquo; вы&nbsp;даёте согласие на&nbsp;обработку cookie-файлов
                            (подробнее в&nbsp;
                            <Link href="https://cdn.m2.ru/assets/pdfs/privacy-policy.pdf" target="blank">
                                Политике
                            </Link>
                            ). М2&nbsp;обрабатывает cookie, в&nbsp;том числе через сервисы веб-аналитики Яндекс Метрика
                            и&nbsp;MyTracker с&nbsp;целью обеспечения функционирования сайта, аналитики действий
                            на&nbsp;сайте и&nbsp;улучшения качества обслуживания. <br /> <br />
                            Нажимая &laquo;Отклонить все&raquo; или продолжая использовать сайт М2&nbsp;будет
                            обрабатывать только функциональные cookie-файлы, необходимые для работы сайта. Чтобы
                            отказаться от&nbsp;использования cookie-файлов (в&nbsp;том числе функциональных), вам
                            следует прекратить использовать сайт.
                        </Typography>
                    </Collapse.Item>
                </Collapse>
                <div className="cookie-consent-button">
                    <Button dataTest="reject-cookie-button" fullWidth onClick={rejectCookies} size="s">
                        Отклонить все
                    </Button>
                    <Button dataTest="accept-cookie-button" fullWidth onClick={acceptCookies} size="s">
                        Принять все
                    </Button>
                </div>
            </div>
        </div>
    );
};
