import React, { useEffect } from 'react';
import { User } from '@vtblife/event-bus-events';

import { Survey } from './components/survey/survey';
import { RegionModals } from './components/region-modals/region-modals';
import { CookieConsent } from './components/cookie-consent/cookie-consent';
import { ChatStub } from './components/chat-stub';
import { useUserRegion } from './hooks/use-user-region';
import { useSurvey } from './hooks/use-survey';
import { useCheckAuthState } from '../hooks/use-check-auth-state';
import { useUpdates } from './hooks/use-updates';
import { useReportModal } from './hooks/report-modal';
import {
    ConfigContext,
    ShellAuthorizeContextValue,
    ShellConfigContextValue,
    ShellContextValue,
    ShellUserRegionContextValue,
} from './contexts';
import { ReportModal } from './components/report-modal/report-modal';
import { useSyncAuthState } from '../hooks/use-sync-auth-state';
import { Captcha } from '../captcha/captcha';
import { OfflineEventListener } from './components/offline-event-listener/offline-event-listener';
import { checkCaptchaResourcesAvailable } from '../captcha/check-captcha-resources';
import { useNotifications } from './hooks/use-notifications';
import { WebPushAcceptModal } from './components/web-push-accept-modal/web-push-accept-modal';
import { useInitServiceWorker } from './hooks/use-init-service-worker';
import { useSentryReplay } from './hooks/use-sentry-replay';

interface ShellProps {
    user: User | null;
    verification?: { isAlreadyPassedM2ProVerification?: boolean };
    shouldDisableRegionModal?: boolean;
    shouldAskCookieAgreement?: boolean;
    shouldShowChatWidget?: boolean;
    disableCheckAuthState?: boolean;
    children: React.ReactNode;
    config: ConfigContext;
    pathname: string;
    modalStore?: {
        openModal: (name: string) => void;
        closeModal: (name: string) => void;
        isOpen: (name: string) => boolean;
    };
}

const ShellContent: React.FC<Omit<ShellProps, 'user' | 'config' | 'verification'>> = ({
    shouldDisableRegionModal,
    shouldAskCookieAgreement,
    shouldShowChatWidget,
    disableCheckAuthState,
    modalStore,
    children,
    pathname,
}: ShellProps) => {
    const survey = useSurvey();
    const reportModal = useReportModal();
    const updates = useUpdates();
    const userRegion = useUserRegion();
    useSentryReplay();

    useEffect(() => {
        userRegion.callInitEvent();
        userRegion.fetchUserRegion();
        checkCaptchaResourcesAvailable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { subscribe, shouldShowSubscribeModal } = useNotifications({
        openModal: modalStore?.openModal,
        pathname: pathname,
    });
    const { workbox } = useInitServiceWorker();
    useCheckAuthState(Boolean(disableCheckAuthState));
    useSyncAuthState();

    return (
        <ShellContextValue.Provider
            value={{
                survey,
                updates,
                reportModal,
                serviceWorker: {
                    workbox,
                },
            }}
        >
            <ShellUserRegionContextValue.Provider
                value={{
                    userRegion,
                }}
            >
                {children}
                <Survey />
                <Captcha />
                {!shouldDisableRegionModal && <RegionModals />}
                {shouldAskCookieAgreement && <CookieConsent />}
                <ReportModal />
                {shouldShowSubscribeModal && <WebPushAcceptModal modalStore={modalStore} subscribe={subscribe} />}
                {shouldShowChatWidget && (
                    <>
                        <ChatStub />
                    </>
                )}
                <OfflineEventListener />
            </ShellUserRegionContextValue.Provider>
        </ShellContextValue.Provider>
    );
};

export const Shell: React.FC<ShellProps> = ({
    user,
    children,
    shouldDisableRegionModal,
    shouldAskCookieAgreement,
    shouldShowChatWidget,
    disableCheckAuthState,
    config,
    pathname,
    modalStore,
    verification,
}: ShellProps) => {
    const isAuthorized = Boolean(user && user.isAuthenticated);

    return (
        <ShellAuthorizeContextValue.Provider
            value={{
                authorize: {
                    user,
                    isAuthorized,
                    verification,
                },
            }}
        >
            <ShellConfigContextValue.Provider
                value={{
                    config,
                }}
            >
                <ShellContent
                    shouldAskCookieAgreement={shouldAskCookieAgreement}
                    disableCheckAuthState={disableCheckAuthState}
                    shouldShowChatWidget={shouldShowChatWidget}
                    shouldDisableRegionModal={shouldDisableRegionModal}
                    modalStore={modalStore}
                    pathname={pathname}
                >
                    {children}
                </ShellContent>
            </ShellConfigContextValue.Provider>
        </ShellAuthorizeContextValue.Provider>
    );
};
