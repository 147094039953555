import type {
    NotificationEvent,
    NotificationEventType,
    NotificationPayload,
    ReportableErrorPayload,
    ReportableErrorType,
    ReportableErrorEvent,
} from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';
import {
    composeNotification,
    reportErrorToSentry,
    getTrivialErrorTypesMessage,
    type ReportableErrorData,
    type AdditionalClientDataService,
} from '@vtblife/layout-components/shell/utils';
import { showToasterNotification } from '@vtblife/layout-components/shell/components/notification-toaster/toaster-adapter/toaster-adapter';

import type { ErrorNotification, ErrorNotificationEventType } from '../../models/events/error';
import { AppError } from '../../models/app-error.model';

export function configureBusEventsNotification(additionalClientDataService?: AdditionalClientDataService) {
    async function notificationHandler({ data }: NotificationEvent): Promise<void> {
        let notificationPayload = data;
        if (data.reportableErrorData?.error || data.options?.error) {
            const { sentryEventId, extractedData } = await reportErrorToSentry({
                ...(data.reportableErrorData as ReportableErrorData),
                error: data.reportableErrorData?.error || data.options?.error, // TO DO: remove `data.options?.error` fallback when it will be removed at event-bus-events interfaces
                additionalData: additionalClientDataService?.getData(),
                notificationPayload,
                description: 'An error provided by EventBus.Event and handled in root app. Type is `app:notification`.',
                extra: data.options?.extra,
            });
            notificationPayload = composeNotification(extractedData, sentryEventId);
        }
        showToasterNotification(notificationPayload);
    }

    function errorNotificationHandler({ data: { type, message } }: ErrorNotification): void {
        showToasterNotification({
            type: 'error',
            message: message || getTrivialErrorTypesMessage(type),
        });
    }

    function errorHandler({ data }: ReportableErrorEvent): void {
        reportErrorToSentry({
            ...(data as ReportableErrorData),
            additionalData: additionalClientDataService?.getData(),
            description: 'An error provided by EventBus.Event and handled in Root app. Type is `app:error`.',
        });
    }

    const eventBus = (window.eventBus = EventBus.getInstance());
    eventBus.subscribe<ErrorNotificationEventType, AppError>('error:notification', errorNotificationHandler);
    eventBus.subscribe<NotificationEventType, NotificationPayload>('app:notification', notificationHandler);
    eventBus.subscribe<ReportableErrorType, ReportableErrorPayload>('app:error', errorHandler);
}
