import React, { useEffect } from 'react';
import { Typography, RRLink } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import styles from './kyc-verification-reminder.module.css';
import { KYC_VERIFICATION_LINK } from './constants';
import { sendKycReminderAnalyticsEvent } from './utils';

export const KycVerificationHeader: React.FC<{ onClose: VoidFunction }> = ({ onClose }) => {
    useEffect(() => {
        sendKycReminderAnalyticsEvent('verification_tape_show');
    }, []);

    const handleClose = () => {
        sendKycReminderAnalyticsEvent('verification_tape_close');
        onClose();
    };
    return (
        <div className={styles.header}>
            <RRLink
                onClick={() => {
                    sendKycReminderAnalyticsEvent('verification_tape_confirm');
                }}
                href={KYC_VERIFICATION_LINK}
                target="blank"
            >
                <span className={styles.headerContent}>
                    <Typography color="white-500">Проводите сделки безопасно</Typography>
                    <Typography bold color="white-500">
                        Подтвердить личность&nbsp;&nbsp;
                        <Icon name="arrow-right" />
                    </Typography>
                </span>
            </RRLink>
            <button type="button" className={styles.closeButton} onClick={handleClose}>
                <Icon color="var(--c-text-minor)" size="s" name="cross" />
            </button>
        </div>
    );
};
