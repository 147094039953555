import { useEffect } from 'react';
import type {
    SentryReplayNotificationEvent,
    SentryReplayNotificationPayload,
    SentryReplayNotificationType,
    SentryRestartReplayType,
} from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import {
    composeSentryReplayNotification,
    type ReportableErrorData,
    reportErrorToSentry,
    restartSentryReplay,
} from '../utils';
import { showToasterNotification } from '../components/notification-toaster/toaster-adapter/toaster-adapter';

async function notificationReplayHandler({ data }: SentryReplayNotificationEvent): Promise<void> {
    const { sentryEventId } = await reportErrorToSentry({
        ...(data.reportableErrorData as ReportableErrorData),
        error: data.reportableErrorData?.error,
        notificationPayload: data,
        extra: data.options?.extra,
    });
    showToasterNotification(composeSentryReplayNotification(data, sentryEventId));
    if (data?.restartReplay) {
        await restartSentryReplay();
    }
}

async function notificationRestartReplayHandler(): Promise<void> {
    await restartSentryReplay();
    showToasterNotification({
        type: 'success',
        message: 'Sentry Replay перезапущен успешно. Начата новая сессия записи.',
    });
}

type M2BroMessageEvent = { data: { type: 'M2_BRO'; action: 'EVENT_BUS_PUBLISH'; data: SentryReplayNotificationEvent } };

const handleMessage = function (event: M2BroMessageEvent) {
    if (event.data.type !== 'M2_BRO') return;
    const eventBus = (window.eventBus = EventBus.getInstance());
    if (event.data.action === 'EVENT_BUS_PUBLISH') {
        eventBus.publish(event.data.data);
    }
};

export const useSentryReplay = () => {
    useEffect(() => {
        window?.addEventListener('message', handleMessage);
        const eventBus = EventBus.getInstance();
        const subs = [
            eventBus.subscribe<SentryReplayNotificationType, SentryReplayNotificationPayload>(
                'app:sentry-replay-notification',
                notificationReplayHandler,
            ),
            eventBus.subscribe<SentryRestartReplayType, null>(
                'app:sentry-restart-replay',
                notificationRestartReplayHandler,
            ),
        ];

        return () => {
            window?.removeEventListener('message', handleMessage);
            subs.forEach((sub) => sub.unsubscribe());
        };
    }, []);
};
