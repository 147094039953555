import { useEffect, useState } from 'react';
import type { Route } from '@vtblife/layout-config/types';

import { growthBookApiService } from '../../services/growth-book-api-service';

// INFO: Hardcoded condition, so it doesn't affect other routes
function shouldUseGrowthBook(route: Route) {
    return '/personal-area' === route.path;
}

function useGrowthBookFeaturesByPath(route: Route) {
    const [ready, setReady] = useState(!shouldUseGrowthBook(route));

    useEffect(() => {
        const fetchData = async () => {
            const path = route.path;
            const data = await growthBookApiService.getFeaturesByPath(path);
            console.debug('useGrowthBookRouteTracking', data);

            // Store feature values
            if (!window.GROWTH_BOOK_FEATURES) {
                window.GROWTH_BOOK_FEATURES = {};
            }
            window.GROWTH_BOOK_FEATURES = {
                ...window.GROWTH_BOOK_FEATURES,
                ...data.features,
            };

            // Send Analytics
            data.trackingData.forEach(({ experiment, result }) =>
                window.dataLayer.push({
                    event: 'experiment_viewed',
                    experiment_id: experiment.key,
                    variation_id: result.key,
                    hash_attribute: result.hashAttribute,
                    hash_value: result.hashValue,
                }),
            );
        };

        if (shouldUseGrowthBook(route)) {
            fetchData()
                .catch((error) => {
                    console.error('Error fetching GrowthBook features', error);
                })
                .finally(() => setReady(true));
        }
    }, [route.path]);

    return { ready };
}

export { useGrowthBookFeaturesByPath };
